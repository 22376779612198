(function () {
  'use strict';

  var $header = $('#header');
  var $window = $(window);
  var $body = $('body');
  var scroll_top = $body.scrollTop();
  var trigger = 100;
  var is_pinned = scroll_top > trigger;

  var update = function () {
    scroll_top = $window.scrollTop();
    if (!is_pinned && scroll_top > trigger) {
      $header.addClass('is-pinned');
      is_pinned = true;
    } else if (is_pinned && scroll_top <= trigger) {
      $header.removeClass('is-pinned');
      is_pinned = false;
    }
  }

  $window.on('scroll', update);

  update();
})();
