(function () {
  'use strict';

  var element = document.getElementById("scroll-indicator-progress");
  var pathLength = element.getAttribute('width') * 2 + element.getAttribute('height') * 2;


  element.style.strokeDasharray = pathLength;
  element.style.strokeDashoffset = pathLength;

  var update = function () {
    var scroll_percent = getScrollPercent();
    element.style.strokeDashoffset = pathLength - pathLength * scroll_percent;
  };

  var getScrollPercent = function() {
    var height = document.documentElement,
        body = document.body,
        scroll_top = 'scrollTop',
        scroll_height = 'scrollHeight';
    return (height[scroll_top]||body[scroll_top]) / ((height[scroll_height]||body[scroll_height]) - height.clientHeight);
  }

  document.addEventListener('scroll', update);

  update();
})();
