(function() {
  'use strict';

  // Init
  var $cards = $('.js-card');
  var $document = $(document);

  $cards.each(function (index, card) {
    var picture = card.querySelector(".js-card-picture");

    // Mouse
    var mouse = {
      _x: 0,
      _y: 0,
      x: 0,
      y: 0,
      updatePosition: function(event) {
        var e = event || window.event;
        this.x = e.clientX - this._x;
        this.y = (e.clientY - this._y) * -1;
      },
      setOrigin: function(element) {
        var $element = $(element);
        this._x = $element.offset().left - $document.scrollLeft() + Math.floor($element.width() / 2);
        this._y = $element.offset().top - $document.scrollTop() + Math.floor($element.height() / 2);
      },
      show: function() {
        return "(" + this.x + ", " + this.y + ")";
      }
    };

    // Track the mouse position relative to the center of the card.
    mouse.setOrigin(card);

    //-----------------------------------------

    var counter = 0;
    var updateRate = 5;
    var isTimeToUpdate = function() {
      return counter++ % updateRate === 0;
    };

    //-----------------------------------------

    var onMouseEnterHandler = function(event) {
      mouse.setOrigin(card)
      update(event);
    };

    var onMouseLeaveHandler = function() {
      picture.style.cssText = "";
    };

    var onMouseMoveHandler = function(event) {
      if (isTimeToUpdate()) {
        update(event);
      }
    };

    //-----------------------------------------

    var update = function(event) {
      mouse.updatePosition(event);
      updateTransformStyle(
        (mouse.y / picture.offsetHeight / 2).toFixed(2),
        (mouse.x / picture.offsetWidth / 2).toFixed(2)
      );
    };

    var updateTransformStyle = function(x, y) {
      var style = "rotate3d(" + x + ", " + y + ", 0, 1deg)";
      picture.style.transform = style;
      picture.style.webkitTransform = style;
      picture.style.mozTransform = style;
      picture.style.msTransform = style;
      picture.style.oTransform = style;
    };

    //-----------------------------------------

    card.onmouseenter = onMouseEnterHandler;
    card.onmouseleave = onMouseLeaveHandler;
    card.onmousemove = onMouseMoveHandler;
  });
})();
