/**
 *
 * Require window.requestanimationframe
 * Require intersection-observer
 *
 */

(function () {
  'use strict';

  var $banners = $('.js-banner');
  var $window = $(window);

  $banners.each(function (index, banner) {
    var $banner = $(banner);
    var $buttons = $banner.find('.js-banner-button');
    var pointer = { x: 0, y: 0 };
    var temp = { x: 0, y: 0 };
    var initial_orientation = false;
    var is_in_viewport = false;
    var orientation = false;
    var center;

    var observer = new IntersectionObserver(function (observables) {
      var $observables = $(observables);
      $observables.each(function (index, observable) {
        if (observable.intersectionRatio > 0.1) {
          is_in_viewport = true;
        } else {
          is_in_viewport = false;
        }
      });
    }, {
      threshold: [0.1]
    });

    observer.observe(banner);

    var init = function () {
      center = { x: $banner.offset().left + $banner.outerWidth() / 2, y: $banner.offset().top + $banner.outerHeight() / 2 }
    };

    var loop = function () {
      if(temp != pointer) {
        temp.x = temp.x + (pointer.x - temp.x) / 50;
        temp.y = temp.y + (pointer.y - temp.y) / 50;

        banner.style.setProperty('--cursor-position-x', Math.round(temp.x / 2) + 'px');
        banner.style.setProperty('--cursor-position-y', Math.round(temp.y / 4) + 'px');
      }

      window.requestAnimationFrame(loop);
    };

    var calculate_orientation = function () {
      var beta = Math.round(event.beta * 1000) / 1000;
      var alpha = Math.round(event.alpha * 1000) / 1000;
      var gamma = Math.round(event.gamma * 1000) / 1000;

      if(!initial_orientation) {
        initial_orientation = { alpha: alpha, beta: beta, gamma: gamma };
      }

      var delta = { alpha: alpha - initial_orientation.alpha, beta: beta - initial_orientation.beta, gamma: gamma - initial_orientation.gamma };

      if(delta.beta >= 40) {
        initial_orientation.beta = Math.round((initial_orientation.beta + delta.beta - 40) * 1000) / 1000;
        delta.beta = 40;
      } else if(delta.beta <= -40) {
        initial_orientation.beta = Math.round((initial_orientation.beta + delta.beta + 40) * 1000) / 1000;
        delta.beta = -40;
      }

      if(delta.alpha >= 40) {
        initial_orientation.alpha = Math.round((initial_orientation.alpha + delta.alpha - 10) * 1000) / 1000;
        delta.alpha = 40;
      } else if(delta.alpha <= -40) {
        initial_orientation.alpha = Math.round((initial_orientation.alpha + delta.alpha + 10) * 1000) / 1000;
        delta.alpha = -40;
      }

      if(delta.gamma >= 40) {
        initial_orientation.gamma = Math.round((initial_orientation.gamma + delta.gamma - 40) * 1000) / 1000;
        delta.gamma = 40;
      } else if(delta.gamma <= -40) {
        initial_orientation.gamma = Math.round((initial_orientation.gamma + delta.gamma + 40) * 1000) / 1000;
        delta.gamma = -40;
      }

      var delta_x = 5 * (( ( delta.beta / 90) * delta.alpha ) + ( (1 - (delta.beta - 90) / 90) * delta.gamma ));
      var delta_y = -10 * delta.beta;

      if(orientation === "landscape-secondary" || orientation === "landscape-primary") {
        delta_x = -10 * delta.beta;
        delta_y = 5 * (( ( delta.beta / 90) * delta.alpha ) + ( (1 - (delta.beta - 90) / 90) * delta.gamma ));
      }

      pointer = {
        x: delta_x,
        y: delta_y
      };
    }

    $buttons.on('focusin', function() {
      $buttons.addClass('focus-visible');
    });

    $buttons.on('focusout', function() {
      $buttons.removeClass('focus-visible');
    });

    $buttons.on('mouseover', function() {
      $buttons.addClass('is-hovered');
    });

    $buttons.on('mouseout', function() {
      $buttons.removeClass('is-hovered');
    });

    $window.on('mousemove', function (event) {
      if(is_in_viewport) {
        pointer = { x: event.pageX - center.x, y: event.pageY - center.y };
      }
    });

    $window.on('resize', function (event) {
      init();
    });

    if(Modernizr.touchevents) {
      pointer.x = $banner.outerWidth() / 2;
    }

    if(window.DeviceOrientationEvent) {
      window.addEventListener('deviceorientation', function (event) {
        if(is_in_viewport) {
          calculate_orientation();
        }
      });

      if(window.screen.hasOwnProperty('orientation')) {
        orientation = window.screen.orientation.type;

        window.addEventListener('orientationchange', function (event) {
          orientation = window.screen.orientation.type;
        });
      }
    }

    init();
    window.requestAnimationFrame(loop);
  });
})();
