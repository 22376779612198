/**
 *
 * Require slick-carousel
 *
 */

(function () {
  'use strict';

  var $sliders = $('.js-slider');

  $sliders.each(function (indes, slider) {
    var $slider = $(slider);
    var $carousel = $slider.find('.js-slider-carousel');
    var $pagination = $slider.find('.js-slider-pagination');
    var options = {
      infinite: true,
      centerMode: true,
      centerPadding: '12%',
      arrows: false,
      dots: true,
      appendDots: $pagination,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 999,
          settings: {
            centerPadding: '20%',
          }
        },
        {
          breakpoint: 779,
          settings: {
            centerPadding: '10%',
          }
        },
        {
          breakpoint: 479,
          settings: {
            centerPadding: '20%',
          }
        },
      ],
      customPaging: function (slider, i) {
        var label = $(slider.$slides[i]).find('.js-slider-slide').attr('data-label');
        return '<button class="js-cursor-hoverable"><span>' + label + '</span></button>'
      },
    };

    $carousel.slick(options);
  });
})();
