(function () {
  'use strict';

  var $burgers = $('.js-burger');
  var $window = $(window);

  $burgers.each(function (index, burger) {
    var $burger = $(burger);
    var $target = $('#' + $burger.attr('aria-controls'));
    var state = $burger.attr('aria-expanded');
    var is_togglable = $burger.css("display") == "none";

    var toggle_target = function () {
      $burger.attr('aria-expanded', !state);
      $target.attr('aria-hidden', state);
      state = !state;
    };

    var activate = function () {
      $burger.attr('aria-expanded', false);
      $target.attr('aria-hidden', true);
      state = false;
    };

    var deactivate = function () {
      $burger.removeAttr('aria-expanded');
      $target.removeAttr('aria-hidden');
      state = true;
    };

    var update = debounce(function () {
      init();
    }, 250);

    var init = function () {
      if(is_togglable != ($burger.css("display") != "none")) {
        is_togglable = $burger.css("display") != "none";
        is_togglable ? activate() : deactivate();
      }
    };

    $burger.on('click', toggle_target);

    $window.on('resize', update);

    init();
  });
})();
