(function () {
  'use strict';

  if (typeof(wp_env) !== 'undefined' && wp_env != 'production') {
    return;
  }

  var $cursor = $('#cursor');
  var $pointer = $cursor.find('.js-cursor-pointer');
  var $shape = $cursor.find('.js-cursor-shape');
  var $window = $(window);
  var $document = $(document);
  var hoverables = '.js-cursor-hoverable, .s-content a';
  var pointer = { x: 0, y: 0 };
  var shape = { x: 0, y: 0, scale: 1 };
  var temp = { x: 0, y: 0, scale: 1 };
  var has_moved = false;
  var is_moving = false;
  var is_hovering = false;
  var sensitivity = 15;
  var scale_sensitivity = sensitivity;

  var loop = function () {
    if(is_moving) {
      $pointer[0].style.setProperty('--pointer-position-x', pointer.x + 'px');
      $pointer[0].style.setProperty('--pointer-position-y', pointer.y + 'px');
    }

    if(temp != shape) {
      temp.x = temp.x + (shape.x - temp.x) / 15;
      temp.y = temp.y + (shape.y - temp.y) / 15;
      temp.scale = temp.scale + (shape.scale - temp.scale) / scale_sensitivity;

      $shape[0].style.setProperty('--shape-position-x', Math.round(temp.x) + 'px');
      $shape[0].style.setProperty('--shape-position-y', Math.round(temp.y) + 'px');
      $shape[0].style.setProperty('--shape-scale', temp.scale);
    }

    window.requestAnimationFrame(loop);
  };

  if(!Modernizr.touchevents) {
    $('html').addClass('has-custom-cursor');

    $document.on('mouseover', hoverables, function(event) {
      var $hoverable = $(event.currentTarget);

      if($hoverable.attr('for')) {
        $hoverable = $('#' + $hoverable.attr('for'));
      }

      if(!$hoverable.attr('disabled')) {
        is_hovering = true;
        $cursor.addClass('is-pointing');
        shape = {
          x: $hoverable.offset().left + $hoverable.outerWidth() / 2,
          y: $hoverable.offset().top + $hoverable.outerHeight() / 2,
          scale: Math.max(1, Math.min($hoverable.outerWidth(), $hoverable.outerHeight()) / $shape.outerWidth()),
        };
      }
    });

    $document.on('mouseout', hoverables, function(event) {
      is_hovering = false;
      $cursor.removeClass('is-pointing');
      shape = {
        x: event.pageX,
        y: event.pageY,
        scale: 1,
      };
    });

    $document.on('mousedown', function(event) {
      scale_sensitivity = 3;
      shape.scale = shape.scale * 1.5;
    });

    $document.on('mouseup', function(event) {
      scale_sensitivity = sensitivity;
      shape.scale = shape.scale / 1.5;
    });

    $window.on('mousemove', function (event) {
      pointer = { x: event.clientX, y: event.clientY };

      if(!is_hovering) {
        shape.x = event.pageX;
        shape.y = event.pageY;
      }

      if(!has_moved) {
        temp.x = shape.x;
        temp.y = shape.y;
        temp.scale = shape.scale;
        has_moved = true;
        $cursor.addClass('is-active');
        window.requestAnimationFrame(loop);
      }

      is_moving = true;
    });

    $window.on('scroll', function (event) {
      is_moving = true;
    });
  }
})();
