/**
 *
 * Require intersection-observer
 *
 */


(function() {
  'use strict';

  function init () {
    var $summaries = $('.js-summary');

    $summaries.each(function(index, summary) {
      var $summary = $(summary),
          $anchors = $summary.find('.js-summary-link'),
          observer,
          threshold = [],
          $window = $(window);

      for(var i = 1; i <= 50; i++) {
        threshold.push(i/50);
      }

      observer = new IntersectionObserver(function (observables) {
        var $observables = $(observables);

        $observables.each(function (index, observable) {
          var $observable = $(observable.target);
          var center = $window.height()/2;
          var $anchor = $anchors.filter('[href="#' + $observable.attr('id') + '"]');

          if(observable.boundingClientRect.top < center && observable.boundingClientRect.bottom > center) {
            $anchor.addClass('is-current');
          } else {
            $anchor.removeClass('is-current');
          }
        })
      }, {
        threshold: threshold
      });

      $anchors.each(function(index, link) {
        var $link = $(link),
            section = $($link.attr('href'));

        observer.observe(section[0]);
      });
    });
  }

  init();
})();
